/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap'); */

/* General Styles */
body {
  font-size: 12px;
  margin: 0;
  font-family: "Open Sans", 'verdana', 'arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #282c34;
  /* Dark theme background */
  color: #f2f2f2;
  /* Light text color */
}

code {
  color: #DAA520;
  /* Gold text color for code */
}

/* Toolbar Styles */
.toolbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  background-color: #282c34;
  /* Dark theme background */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.toolbar-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.toolbar-left {
  display: flex;
  align-items: center;
  width: 50%;
  /* Ensure it takes up 50% of the toolbar width */
  justify-content: flex-start;
}

.toolbar-right {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
  /* Ensure it takes up 50% of the toolbar width */
}

/* Remove margin-left for the first button */
/* .toolbar-right>.button-wrapper:first-child { */
  /* margin-left: 5px; */
  /* margin-right: 5px; */
/* } */

/* Wrapper to handle button spacing */
.button-wrapper {
  margin-left: 10px;
}

/* Home Icon Styles */
.home-icon-wrapper {
  display: flex;
  align-items: center;
  margin-right: 10px;
  /* Adds space between the home icon and search bar */
}

/* Search Bar Wrapper */
.search-bar-wrapper {
  position: relative;
  /* Ensure the dropdown is positioned relative to the search bar wrapper */
  width: 100%;
  display: flex;
}

/* Search Bar Styles */
.search-bar {
  width: 100%;
  /* Ensure the search bar takes up all available space */
  background-color: #333333;
  color: #FFFFFF;
  border-radius: 4px;
  padding: 5px 10px;
}

/* Search Results Dropdown Styles */
.search-results-dropdown {
  position: absolute;
  top: 100%;
  /* Ensure dropdown appears just below the search bar */
  left: 0;
  background-color: #282c34;
  /* Dark background */
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  z-index: 200;
  /* Ensure it appears above other elements */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  /* Adds a shadow to the dropdown */
}

.search-results-dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.search-results-dropdown li {
  padding: 10px;
  cursor: pointer;
}

.search-results-dropdown li:hover {
  background-color: red;
  /* Dark hover */
  color: #fff;
  /* Light text on hover */
}

/* Subscription Message Styles */
.subscription-message {
  padding: 0 5px;
  font-weight: bold;
  color: #ff8181;
}

/* Dropdown Menu Styles */
.dropdown {
  position: relative;
}

.dropdown-content {
  position: absolute;
  top: 40px;
  /* Move dropdown below the button */
  left: 0;
  background-color: #282c34;
  /* Dark theme background */
  min-width: 160px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  /* Increased to match other dropdowns */
  padding: 10px;
  border-radius: 4px;
  text-align: left;
}

.dropdown-content a {
  color: #FFFFFF;
  /* White text for dark background */
  padding: 10px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: red;
  /* Slight hover effect */
}

/* Main Grid Container */
main.grid-container {
  display: grid;
  grid-template-columns: repeat(var(--columns), 1fr);
  grid-template-rows: repeat(var(--rows), 1fr);
  height: calc(100vh - 60px);
  /* Adjust height to account for header */
  gap: 10px;
  /* Adjust the gap between sections as needed */
  padding-top: 50px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

main.grid-container>div {
  border: 1px solid #ccc;
  height: 100%;
  /* Ensure grid items take full height */
  overflow: scroll;
  background-color: #1D1D1D;
  /* Dark background for grid items */
}

main.grid-container::-webkit-scrollbar {
  display: none;
}

.grid-item-large {
  grid-column: span 2;
}

/* Highlight for special rows */
.highlight-green {
  background-color: green;
  color: white;
  /* Makes the text easier to read on the green background */
}

/* Highlight for special rows */
.highlight-red {
  background-color: red;
  color: white;
}

/* Media Queries */

/* Tablet and Small Screens */
@media (max-width: 768px) {
  body {
    font-size: 10px;
  }

  .toolbar {
    flex-direction: column;
    height: auto;
    padding: 10px;
  }

  .toolbar-left,
  .toolbar-right {
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;
  }

  /* Ensure the main grid container is scrollable */
  main.grid-container {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 5px;
    max-height: calc(100vh - 120px);
    /* Adjust based on toolbar height */
    overflow-y: auto;
    /* Allow scrolling for the grid container */
    padding-bottom: 20px;
    /* Prevent the last section from being cut off */
  }

  main.grid-container>div {
    height: auto;
    min-height: 30vh;
    /* Each grid item takes up at least 30% of the viewport height */
    overflow-y: auto;
    /* Allow scrolling within each grid item */
  }

  .grid-item-large {
    min-height: 40vh;
    /* Larger grid items take up slightly more space */
  }
}

/* Mobile Devices */
@media (max-width: 480px) {
  body {
    font-size: 8px;
  }

  .toolbar {
    padding: 5px;
  }

  .toolbar-left,
  .toolbar-right {
    margin-bottom: 5px;
  }

  main.grid-container>div {
    min-height: 35vh;
    /* Smaller screens show larger sections */
  }

  .grid-item-large {
    min-height: 40vh;
  }
}