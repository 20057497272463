/* ChartSection.css */

/* Chart container for flexible width */
.chart-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.chart-title {
  font-weight: bold;
  margin: 1px;
  color: #ffffff; /* Adjust as per your theme */
}

.chart-container {
  width: 100%;
  height: 100%;
  max-height: 400px;  /* Ensure it scales to fit within the grid */
}

.signal-chart-wrapper {
  width: 100vw; /* Full viewport width */
  height: 100vh; /* Full viewport height minus some margin for the header */
  display: flex;
  justify-content: center;
  align-items: center;
}