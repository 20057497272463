/* TradePopup.css */

/* Adjust modal styling */
.modal-content {
    background-color: #2A2A2A;
    border: 2px solid red;
    color: #E0E0E0;
    z-index: 1050;
    position: fixed;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    width: 25rem; /* Increased width */
    max-width: 90%; /* Ensure it doesn't exceed the screen width on smaller screens */
    padding: 20px; /* Add some padding for better spacing */
}

.modal-overlay {
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1040;
}

/* Ensure modal is centered and above other elements */
.ReactModal__Content {
    top: 50% !important;
    left: 50% !important;
    right: auto !important;
    bottom: auto !important;
    margin-right: -50% !important;
    transform: translate(-50%, -50%) !important;
    z-index: 1050 !important;
}

.modal-footer {
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
}

.form-control {
    background-color: #3A3A3A;
    color: #E0E0E0;
    border: 1px solid #555;
}

.form-group{
    margin-bottom: 10px;
}