/* General page styling */
.home-page {
  background-color: #f5f7fb; /* Soft light lavender/grey */
  color: #5e568e;
  padding: 20px;
}

/* Hero Banner */
.hero-banner {
  text-align: center;
  margin-bottom: 40px;
}

.hero-banner h1 {
  font-size: 3em;
  font-family: "Open Sans", 'verdana', 'arial', sans-serif, cursive;
  color: #5e568e;
  margin-bottom: 10px;
}

.hero-banner p {
  margin: 10px auto;
  font-size: 1.5em;
  color: #555555;
}

.cta-button {
  display: inline-block;
  margin-top: 20px;
  padding: 12px 24px;
  color: #fff;
  background-color: #81c784; /* Light green */
  border-radius: 5px;
  font-weight: bold;
  text-decoration: none;
  font-size: 1.5em;
}

/* Service Highlights */
.service-highlights {
  text-align: center;
  margin-bottom: 40px;
}

.service-highlights h2 {
  font-size: 2em;
  font-weight: bold;
  color: #5e568e;
  margin-bottom: 20px;
  background-color: #f4f4f4;
  padding: 10px;
}

.service-cards {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
}

.service-card {
  color: #5e568e;
  padding: 20px;
  border-radius: 8px;
  width: 25em;
  text-align: center;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  font-size: 1.25em;
  transition: transform 0.3s ease;
  background-color: var(--service-card-color); /* Set by inline style */  
}

.service-card:hover {
  transform: translateY(-5px);
}

.know-more-link {
  color: #81c784;
  text-decoration: underline;
  font-size: 0.9em;
}

/* Philosophy Section */
.philosophy-section {
  text-align: center;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 8px;
  margin-bottom: 40px;
}

.philosophy-section h2 {
  font-size: 2em;
  font-weight: bold;
  color: #5e568e;
  margin-bottom: 10px;
}

.philosophy-section p {
  font-size: 1.25em;
  color: #666666;
  max-width: 800px;
  margin: 0 auto;
}

/* Testimonials Section */
.testimonials-section {
  text-align: center;
  margin-bottom: 40px;
}

.testimonials-section h2 {
  font-size: 2em;
  font-weight: bold;
  color: #5e568e;
  margin-bottom: 20px;
}

.testimonials {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
}

.testimonial-card {
  background-color: #f4f4f4;
  padding: 15px;
  border-radius: 8px;
  max-width: 250px;
  text-align: center;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  font-size: 1em;
}

.testimonial-card p {
  font-style: italic;
  color: #5e568e;
}

.testimonial-card span {
  font-size: 0.9em;
  color: #777777;
  font-weight: bold;
}

/* Contact Section */
.contact-section {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center-align horizontally */
  justify-content: center; /* Center-align vertically */
  text-align: center;
  margin-top: 40px;
}

.contact-section h2 {
  font-size: 2em;
  font-weight: bold;
  color: #5e568e;
  margin-bottom: 10px;
  text-align: center; /* Ensure heading is center-aligned */
}

.contact-section p {
  font-size: 1.1em;
  color: #666666;
  text-align: center; /* Ensure paragraph text is center-aligned */
  margin: 10px 0;
}

.contact-info p {
  font-size: 1em;
  color: #555555;
  text-align: center; /* Ensure contact info text is center-aligned */
  margin: 5px 0;
}
/* Footer */
.footer {
  background-color: #333333;
  color: white;
  padding: 40px 0;
  margin-top: 40px;
}

.footer-content {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  text-align: left;
}

.footer-left,
.footer-middle,
.footer-right {
  flex: 1;
}

.footer h3,
.footer h4 {
  font-size: 1.3em;
  font-weight: bold;
  color: #ff6f61;
}

.footer ul {
  list-style: none;
  padding: 0;
}

.footer ul li {
  margin: 8px 0;
  font-size: 1em;
}

.footer ul li a {
  color: white;
  text-decoration: none;
}

.footer ul li a:hover {
  text-decoration: underline;
}

.footer-bottom {
  text-align: center;
  padding-top: 10px;
  font-size: 0.9em;
  color: #aaaaaa;
}

.team-section {
  text-align: center;
  margin-bottom: 40px;
  padding: 20px;
  background-color: #f9f9f9; /* Light background */
  border-radius: 8px;
}

.team-section h2 {
  font-size: 2em;
  font-weight: bold;
  color: #5e568e; /* Match heading color */
  margin-bottom: 20px;
}

.team-members {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.team-card {
  background-color: #fff; /* White background for cards */
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  width: 200px;
}

.team-card h3 {
  font-size: 1.2em;
  color: #4caf50; /* Highlight team member name */
  margin-bottom: 8px;
}

.team-card p {
  font-size: 1em;
  color: #555;
}