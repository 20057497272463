/* Styles for the table section */
.table-section {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 5px 5px 5px 20px;
  box-sizing: border-box;
}

.table-section table {
  width: 100%;
}

.table-section th,
.table-section td {
  padding: 1px;
  text-align: left;
}

.table-section th {
  white-space: nowrap;
  background-color: #706767;
  color: #DAA520;
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 5;
}

.table-section h2 {
  position: absolute;
  top: 50%;
  transform: rotate(-90deg) translateY(-50%);
  transform-origin: left center;
  font-size: 0.8rem;
  margin: 0;
  color: #124812;
  background-color: #DAA520;
  text-align: center;
  width: 150px;
}

/* Highlight table row on hover */
.table-section tr:hover {
  background-color: #636333;
  color: #FFFFFF;
}

/* Table container scrollable */
.table-section .table-container {
  flex: 1;
  overflow: auto;
}

.table-container {
  overflow-y: auto;
  overflow-x: auto;
}

.table-section-nowrap .table-container {
  white-space: nowrap;
}

/* Styles for links */
.table-section a {
  color: inherit;
  text-decoration: none;
}

.table-section a:hover {
  text-decoration: underline;
}